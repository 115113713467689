import { sql } from "kysely";

import { assert } from "~/util";

/**
 * Quotes a string to be used as a SQL identifier.
 * It is an error to call this function with a string that contains the zero code point.
 */
export function quoteIdentifier(id: string) {
  // According to https://www.postgresql.org/docs/current/sql-syntax-lexical.html#SQL-SYNTAX-IDENTIFIERS,
  // any string may be used as an identifier, except those that contain the zero code point.
  //
  // In order to support special characters, quoted identifiers must be used.
  // Within a quoted identifier, the literal double-quote character must be escaped
  // by writing it twice.
  // For example, the identifier foo" is represented as "foo""" (including the quotes).

  // Materialize never allows any identifiers to be used whose name contains the null byte,
  // so this assert should never fire unless this function is called with arbitrary user input.
  assert(id.search("\0") === -1);

  return `"${id.replace('"', '""')}"`;
}

export function escapedLiteral(literal: string) {
  assert(literal.search("\0") === -1);

  return sql.lit(literal.replace("'", "''"));
}

export function escapedIdentifier(identifier: string) {
  assert(identifier.search("\0") === -1);

  return sql.id(identifier.replace("'", "''"));
}
