import {
  CompiledQuery,
  DatabaseConnection,
  Driver,
  Kysely,
  PostgresAdapter,
  PostgresIntrospector,
  PostgresQueryCompiler,
  QueryResult,
  TransactionSettings,
} from "kysely";
import React from "react";
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

import { useAuth } from "~/api/auth";
import {
  currentEnvironmentState,
  EnabledEnvironment,
} from "~/recoil/environments";

import { DB as MaterializeSchema } from "../../../types/materialize";
import { executeSql } from "../materialized";

export const useDb = () => {
  const { user } = useAuth();
  const environment = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    currentEnvironmentState
  );

  return React.useMemo(() => {
    if (environment?.state !== "enabled") {
      return;
    }
    return new Kysely<MaterializeSchema>({
      dialect: {
        createAdapter() {
          return new PostgresAdapter();
        },
        createDriver() {
          return new MaterializeHttpDriver(environment, user?.accessToken);
        },
        createIntrospector(datbase: Kysely<unknown>) {
          return new PostgresIntrospector(datbase);
        },
        createQueryCompiler() {
          return new PostgresQueryCompiler();
        },
      },
    });
  }, [environment, user?.accessToken]);
};

export class MaterializeHttpDriver implements Driver {
  #environment: EnabledEnvironment;
  #accessToken: string;

  constructor(environment: EnabledEnvironment, accessToken: string) {
    this.#environment = environment;
    this.#accessToken = accessToken;
  }

  async init(): Promise<void> {
    // Nothing to do here
  }

  async acquireConnection(): Promise<DatabaseConnection> {
    return new MaterializeHttpConnection(this.#environment, this.#accessToken);
  }

  async beginTransaction(
    _connection: DatabaseConnection,
    _settings: TransactionSettings
  ): Promise<void> {
    // TODO
  }
  async commitTransaction(_connection: DatabaseConnection): Promise<void> {
    // TODO
  }
  async rollbackTransaction(_connection: DatabaseConnection): Promise<void> {
    // TODO
  }
  async releaseConnection(_connection: DatabaseConnection): Promise<void> {
    // With HTTP, we don't have a connection pool
  }
  async destroy(): Promise<void> {
    // Same here, no cleanup with HTTP
  }
}

class MaterializeHttpConnection implements DatabaseConnection {
  #environment: EnabledEnvironment;
  #accessToken: string;

  constructor(environment: EnabledEnvironment, accessToken: string) {
    this.#environment = environment;
    this.#accessToken = accessToken;
  }

  async executeQuery<R>(
    compiledQuery: CompiledQuery<unknown>
  ): Promise<QueryResult<R>> {
    const result = await executeSql(
      this.#environment,
      {
        queries: [
          {
            query: compiledQuery.sql,
            params: compiledQuery.parameters as string[],
          },
        ],
        cluster: "mz_introspection",
      },
      this.#accessToken
    );

    if ("errorMessage" in result) {
      throw new Error(result.errorMessage);
    }

    const res = result.results?.[1];
    let rowObjects: Record<string, unknown>[] = [];

    if (res) {
      const { columns, rows } = res;
      rowObjects = rows.map((r: Record<string, unknown>) => {
        const o: Record<string, unknown> = {};
        for (let i = 0; i < columns.length; i++) {
          o[columns[i]] = r[i];
        }
        return o;
      });
    }

    return {
      numUpdatedOrDeletedRows: BigInt(0),
      numAffectedRows: BigInt(0),
      rows: rowObjects as R[],
    };
  }
  streamQuery<R>(
    _compiledQuery: CompiledQuery<unknown>,
    _chunkSize?: number | undefined
  ): AsyncIterableIterator<QueryResult<R>> {
    throw new Error("Method not implemented.");
  }
}
