import {
  DummyDriver,
  Kysely,
  PostgresAdapter,
  PostgresIntrospector,
  PostgresQueryCompiler,
  sql,
} from "kysely";

import { assert } from "~/util";

import { DB as MaterializeSchema } from "../../../types/materialize";
import { escapedIdentifier as id, escapedLiteral as lit } from ".";
import { Cluster } from "./useClusters";
import { Connection } from "./useConnections";

export const createDb = () => {
  return new Kysely<MaterializeSchema>({
    dialect: {
      createAdapter() {
        return new PostgresAdapter();
      },
      createDriver() {
        // You need a driver to be able to execute queries. In this example
        // we use the dummy driver that never does anything.
        return new DummyDriver();
      },
      createIntrospector(datbase: Kysely<unknown>) {
        return new PostgresIntrospector(datbase);
      },
      createQueryCompiler() {
        return new PostgresQueryCompiler();
      },
    },
  });
};
export interface CreateSourceParameters {
  name: string;
  connection: Connection | null;
  cluster: Cluster | null;
  publication: string;
  allTables: boolean;
  tables: {
    name: string;
    alias: string;
  }[];
}

const createSourceStatement = (params: CreateSourceParameters) => {
  assert(params.connection?.name);
  assert(params.cluster?.name);
  const db = createDb();

  const query = sql`
CREATE SOURCE ${id(params.name)}
IN CLUSTER ${id(params.cluster.name)}
FROM POSTGRES CONNECTION ${id(params.connection.name)} (PUBLICATION ${lit(
    params.publication
  )})
${
  params.allTables
    ? sql`FOR ALL TABLES`
    : sql`FOR TABLES (${sql.join(
        params.tables.map(
          (t) => sql`${id(t.name)}${t.alias ? sql` AS ${id(t.alias)}` : sql``}`
        )
      )})`
};`;

  return query.compile(db).sql;
};

export default createSourceStatement;
