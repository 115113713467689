import { CompiledQuery, QueryResult } from "kysely";
import React, { useState } from "react";

import { ConnectorStatus, SchemaObject } from "~/api/materialized";
import { assert } from "~/util";

import { useDb } from "./db";

export interface Source extends SchemaObject {
  type: string;
  size: string | null;
  status: ConnectorStatus | null;
  error: string | null;
}

export type OnSuccess<T> = (data?: QueryResult<T> | null) => void;
export type OnError = (error?: Error) => void;

export function useQuery<T>(query: CompiledQuery<T> | null) {
  const db = useDb();
  const [loading, setLoading] = useState<boolean>(false);
  const requestIdRef = React.useRef(1);
  const controllerRef = React.useRef<AbortController>(new AbortController());
  const [results, setResults] = useState<QueryResult<T> | null>(null);
  const [error, setError] = useState<string | null>(null);

  const runSql = React.useCallback(
    async (
      innerQuery: CompiledQuery<T> | null,
      onSuccess?: OnSuccess<T>,
      onError?: OnError
    ) => {
      if (!db || !innerQuery) return;
      controllerRef.current = new AbortController();
      const timeout = setTimeout(() => controllerRef.current.abort(), 5_000);
      const requestId = requestIdRef.current;
      try {
        setLoading(true);
        const result = await db.executeQuery(innerQuery);
        onSuccess?.(result);
        setResults(result);
        if (requestIdRef.current > requestId) {
          // a new query has been kicked off, ignore these results
          return;
        }
      } catch (err: any) {
        if ((err as Error)?.name === "AbortError") {
          return;
        }
        if (err.message) {
          onError?.(err.message ?? "Query failed");
        }
        setError("oops");
      } finally {
        clearTimeout(timeout);
        setLoading(false);
      }
    },
    [db]
  );

  const abortRequest = React.useCallback(() => {
    requestIdRef.current += 1;
    controllerRef.current.abort();
  }, []);

  React.useEffect(() => {
    runSql(query);

    return () => {
      abortRequest();
    };
  }, [query, runSql, abortRequest]);

  const refetch = React.useCallback(() => runSql(query), [query, runSql]);

  const isError = error !== null;

  // When no data has been loaded and query is currently fetching
  const isInitiallyLoading = !isError && results === null;

  return {
    refetch,
    isInitiallyLoading,
    data: results,
    isError,
    error,
    loading,
    runSql,
    abortRequest,
  };
}

/**
 * Fetches all sources in the current environment
 */
function useSources({
  databaseId,
  schemaId,
  nameFilter,
}: { databaseId?: string; schemaId?: string; nameFilter?: string } = {}) {
  const db = useDb();
  const query = React.useMemo(() => {
    if (!db) return null;

    return db
      .selectFrom("mz_catalog.mz_sources as s")
      .select(["s.id", "s.name", "s.type", "s.size"])
      .innerJoin("mz_catalog.mz_schemas as sc", "sc.id", "s.schema_id")
      .select("sc.name as schemaName")
      .innerJoin("mz_catalog.mz_databases as d", "d.id", "sc.database_id")
      .select("d.name as databaseName")
      .leftJoin("mz_internal.mz_source_statuses as st", "st.id", "s.id")
      .select(["st.status", "st.error"])
      .where("s.id", "like", "u%")
      .where("s.type", "<>", "subsource")
      .$if(!!databaseId, (qb) => {
        assert(databaseId);
        return qb.where("d.id", "=", databaseId);
      })
      .$if(!!schemaId, (qb) => {
        assert(schemaId);
        return qb.where("sc.id", "=", schemaId);
      })
      .$if(!!nameFilter, (qb) => {
        assert(nameFilter);
        return qb.where("s.name", "like", `%${nameFilter}%`);
      })
      .compile();
  }, [databaseId, db, nameFilter, schemaId]);

  const sourceResponse = useQuery(query);

  // Ideally we could use InferResult, but then we loose the more specific typing of the status column
  // let sources: InferResult<NonNullable<typeof query>> | null = null;
  let sources: Source[] | null = null;
  if (sourceResponse.data) {
    sources = sourceResponse.data.rows as Source[];
  }

  const getSourceById = (sourceId?: string) =>
    sources?.find((s) => s.id === sourceId) ?? null;

  return { ...sourceResponse, data: sources, getSourceById };
}

export type SourcesResponse = ReturnType<typeof useSources>;

export default useSources;
